import React, { useEffect, useState } from 'react';
import { useUpdateEffect, useReactive } from "ahooks";
import { makeStyles } from '@mui/styles';
import { Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import AppPrimaryArea from '../../components/AppPrimaryArea';
import AppDataTable from '../../components/AppDataTable';
import useAppContext from '../../hooks/useAppContext';
import { dateToTimestamp, formatDate, routeMap } from '../../data-helper';
import AppForm from '../../components/AppForm';

const dateRangeSchema = yup.array()
  .test('dateRange', '正しい日時を入力してください', (value) => {
    if (value && value[0] && value[1] && dateToTimestamp(value[0]) > dateToTimestamp(value[1])) {
      return false;
    }
    return true;
  });

const formFields = [
  { name: "keyword", label:"", placeholder: "キーワードを入力してください", type: "text", size: 2.5 },
  { name: "_id", label: "No", type: "select" },
  { name: "construct", label: "VC名", type: "select" },
  { name: "issuanceDate", label: "発行日時", type: "daterange", schema: dateRangeSchema, size: 1.5 },
  { name: "issuer", label: "発行者名", type: "select" },
  { name: "issuerCorporate", label: "発行企業", type: "select" },
  { name: "plantIds", label: "プラントID", type: "select" },
  { name: "search", label: "絞り込み", type: "button", variant: "contained", color: "info", size: 0.8 },
];

const tableFields = [
  { name: "_id", label: "No", type: "text" },
  { name: "constructName", label: "VC名", type: "text" },
  { name: "issuanceDate", label: "発行日時", type: "text" },
  { name: "issuerName", label: "発行者名", type: "text" },
  { name: "issuerCorporateName", label: "発行企業", type: "text" },
  { name: "plantIds", label: "プラントID", type: "text" },
];

const breadcrumbs = [
];

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative"
  },
  vcCreateButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 16,
    marginBottom: 8,
  },
  tabContainer: {
    position: "absolute",
    left: 160,
    display: "flex",
    "& > div:nth-child(1)": {
      borderRadius: "4px 0 0 4px",
      width: 150,
      boxSizing: "border-box",
      fontWeight: 500,
      fontSize: "0.875rem",
      color: "#666666",
      padding: "6px 16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid #C4C4C4",
      borderRight: 0,
      cursor: "pointer"
    },
    "& > div:nth-child(2)": {
      borderRadius: "0 4px 4px 0",
      width: 150,
      boxSizing: "border-box",
      fontWeight: 500,
      fontSize: "0.875rem",
      color: "#666666",
      padding: "6px 16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid #C4C4C4",
      borderLeft: 0,
      cursor: "pointer"
    }
  },
  selectedTab: {
    background: "#DFEFFF",
    color: "#387FB5 !important",
    border: "1px solid #387FB5 !important"
  }
}));

const VCManagePage = () => {
  const classes = useStyles();
  const app = useAppContext();
  
  const tableFieldsCompleted = [
    { name: "_id", label: "No", type: "text" },
    { name: "constructName", label: "VC名", type: "text" },
    { name: "issuanceDate", label: "発行日時", type: "text" },
    { name: "issuerName", label: "発行者名", type: "text" },
    { name: "issuerCorporateName", label: "発行企業", type: "text" },
    { name: "plantIds", label: "プラントID", type: "text" },
    { name: "display", label: "表示", align: "center", type: "verify", format: (d) => `/${routeMap[app.loginInfo.user.role]}/vc-manage/${d}/verify` },
    { name: "download", label: "ダウンロード", align: "center", type: "download" },
  ];

  const [selectList, setSelectList] = useState({});
  const [formData] = useState({
    keyword: "",
    _id: "",
    construct: "",
    issuanceDate: "",
    issuer: "",
    issuerCorporate: "",
    plantIds: "",
  });
  const [formWatchData, setFormWatchData] = useState({
    keyword: "",
    _id: "",
    construct: "",
    issuanceDate: "",
    issuer: "",
    issuerCorporate: "",
    plantIds: "",
  });
  const [constructs, setConstructs] = useState([]);

  const [vcCompleted, setVcCompleted] = useState(true);
  const handleTabChange = (flag) => {
    if (vcCompleted !== flag) {
      setVcCompleted(flag);
    }
  };

  const pageInfo = useReactive({
    count: 0,
    rowsPerPage: 10,
    page: 0,
    selected: []
  });
  const handleChangeSelection = (selected, checked) => {
    if (checked) {
      const existIdList = pageInfo.selected.map(e => e._id);
      const notExistIdList = selected.filter(e => !existIdList.includes(e._id));
      pageInfo.selected = [...pageInfo.selected, ...notExistIdList];
    } else {
      const deleteIdList = selected.map(e => e._id);
      pageInfo.selected = pageInfo.selected.filter(e => !deleteIdList.includes(e._id));
    }
  };
  const searchData = async (isPageChange) => {
    try {
      app.setIsLoading(true);
      if (!isPageChange) {
        pageInfo.page = 0;
        pageInfo.selected = [];
      }

      const params = {
        pageNow: isPageChange ? pageInfo.page + 1 : 1,
        pageSize: pageInfo.rowsPerPage,
        vcCompleted,
        keyword: formWatchData.keyword,
        _id: formWatchData._id,
        construct: formWatchData.construct,
        issuanceDate: formWatchData.issuanceDate ? formWatchData.issuanceDate.map(date => date ? formatDate(date) : "") : "",
        issuer: formWatchData.issuer,
        issuerCorporate: formWatchData.issuerCorporate,
        plantIds: formWatchData.plantIds,
      }
      const [res, count] = await app.http().get('/master/vc', params);
      setConstructs(res.map(e => {
        if (e.plantIds) {
          e.plantIds = e.plantIds.join(",");
        }
        if (e.vc_info) {
          e.issuanceDate = e.vc_info.issuanceDate;
          e.issuerName = e.vc_info.issuerName;
          e.issuerCorporateName = e.vc_info.issuerCorporateName;
        }
        return e;
      }));
      pageInfo.count = count;
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };
  const clickList = {
    search: () => {
      searchData(false);
    }
  }
  useEffect(() => {
    searchData(false);
    const queryOptions = async () => {
      try {
        app.setIsLoading(true);
        const res = await app.http().get('/master/vc-options', {});
        const all = {value: "", label: "全て"}
        setSelectList({_id: [all, ...res.construct.map(e => {return {value: e.value, label: e.value}})], construct: [all, ...res.construct], issuer: [all, ...res.user], issuerCorporate: [all, ...res.corporate], plantIds: [all, ...res.concretePlant.map(e => {return {value: e.value, label: e.value}})]})
      } catch (err) {
        app.handleHttpError(err);
      } finally {
        app.setIsLoading(false);
      }
    };
    queryOptions();
  }, []);

  useUpdateEffect(() => {
    searchData(false);
  }, [pageInfo.rowsPerPage, vcCompleted]);

  useUpdateEffect(() => {
    searchData(true);
  }, [pageInfo.page]);

  const watchFormValues = (values) => {
    setFormWatchData(values);
  };

  const handleVCCreate = async () => {
    try {
      app.setIsLoading(true);
      await app.http().post('/master/vc/create', pageInfo.selected.map(e => e._id));
      app.showMessageDialog('', 'VCを発行しました', null, 'OK');
      pageInfo.selected = [];
      searchData(false);
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };
  
  return (
    <div className={classes.root}>
      <AppPrimaryArea title="VC管理" breadcrumbs={breadcrumbs} size="xl">
        <Container component="main" maxWidth="xl" sx={{ py: 2 }}>
          <AppForm formFields={formFields} data={formData} selectList={selectList} watch={watchFormValues} columns={{ xs: 1, md: 5.3 }} clickList={clickList} />

          <div className={classes.vcCreateButtonContainer}>
            {
              app.loginInfo.user.role !== "ConstructorUser" && app.loginInfo.user.role !== "DataEntry"
              && <Button color="info" variant="contained" onClick={handleVCCreate} disabled={pageInfo.selected.length === 0}>VC発行</Button>
            }
          </div>

          <div className={classes.tabContainer}>
            <div className={vcCompleted ? classes.selectedTab : ""} onClick={() => handleTabChange(true)}>発行済み</div>
            <div className={vcCompleted ? "" : classes.selectedTab} onClick={() => handleTabChange(false)}>未発行</div>
          </div>

          <div style={{marginTop: 16}}>
            {
              vcCompleted ? 
                <AppDataTable groupFields={[]} tableFields={tableFieldsCompleted} data={constructs} pageInfo={pageInfo} pagination={true} />
                : 
                <AppDataTable groupFields={[]} tableFields={tableFields} data={constructs} pageInfo={pageInfo} selection="select" selectId="_id" onChangeSelection={handleChangeSelection} pagination={true} />
            }
          </div>
        </Container>
      </AppPrimaryArea>
    </div>
  );
};

export default VCManagePage;
