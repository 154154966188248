import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Toolbar } from '@mui/material';

import AppHeader from '../common/components/AppHeader';
import AppDrawer from '../common/components/AppDrawer';
import VCManagePage from '../common/pages/vc/VCManagePage';
import ActivityListPage from './activity/ActivityListPage';
import DeliveryListPage from './activity/DeliveryListPage';
import ViewDeliveryPage from './activity/ViewDeliveryPage';
import JCreditReportPage from '../common/pages/jcredit-report/JCreditReportPage';
import JCreditReportViewPage from '../common/pages/jcredit-report/JCreditReportViewPage';
import CO2ReportPage from '../common/pages/co2-report/CO2ReportPage';
import CO2ReportViewPage from '../common/pages/co2-report/CO2ReportViewPage';
import DirectActivityListPage from '../common/pages/co2-report/DirectActivityListPage';
import DirectActivityPage from '../common/pages/co2-report/DirectActivityPage';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
  },
}));

const pages = [
  { name: '現場名・活動量一覧', path: '/constructor-admin/activity' },
  { name: 'J-クレジット申請レポート', path: '/constructor-admin/jcredit-report' },
  { name: 'CO2排出レポート', path: '/constructor-admin/co2-report' },
  { name: 'VC管理', path: '/constructor-admin/vc-manage' },
];

const ConstructorAdminPage = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <AppHeader />
      <AppDrawer pages={pages}></AppDrawer>
      <div className={classes.content}>
        <Toolbar variant="dense" />
        <Routes>
          <Route path="/" element={<Navigate to="./activity" />} />
          <Route path="/activity" element={<ActivityListPage />} />
          <Route path="/activity/:activityId/delivery" element={<DeliveryListPage />} />
          <Route path="/activity/:activityId/delivery/:deliveryId" element={<ViewDeliveryPage />} />
          <Route path="/jcredit-report" element={<JCreditReportPage />} />
          <Route path="/jcredit-report/view" element={<JCreditReportViewPage />} />
          <Route path="/co2-report" element={<CO2ReportPage />} />
          <Route path="/co2-report/view" element={<CO2ReportViewPage />} />
          <Route path="/co2-report/view/direct-activity" element={<DirectActivityListPage />} />
          <Route path="/co2-report/view/direct-activity/:activityId" element={<DirectActivityPage />} />
          <Route path="/vc-manage" element={<VCManagePage />} />
        </Routes>
      </div>
    </React.Fragment>
  );
};

export default ConstructorAdminPage;
