import React from 'react';
import AppForm from '../../../common/components/AppForm'
import { formatVolume } from '../../../common/data-helper';

const formFields = [
  { name: "section1", label: "現場の基本情報", type: "section" },
  { name: "constructName", label: "現場名", type: "text", size: 2, required: true },
  { name: "corporateId", label: "施工主の会社ID", type: "hidden", required: true, readonly: true },
  { name: "corporateName", label: "施工主の会社名", type: "text", required: true, readonly: true },
  { name: "plannedVolume", label: "予定総打設量(m\u00B3)", type: "number", required: true, format: formatVolume },
  { name: "disclosure", label: "プラットフォームに情報を公開", type: "select" },
  { name: "placementCompleted", label: "打設状況", type: "select" },
  { name: "localPrefecture", label: "都道府県から利用プラントを絞り込み", type: "select", size: 2 },
  { name: "plantIds", label: "利用プラント", type: "multiselect", initialValue: [] },
  { name: "postalCode", label: "郵便番号", type: "text", required: true, regex: /\d{3}-\d{4}/, regexMsg: '郵便番号(ハイフン付き)で指定してください' },
  { name: "prefecture", label: "都道府県", type: "select", required: true },
  { name: "address", label: "住所", type: "text", size: 2, required: true },
  { name: "contact", label: "連絡先", type: "text", regex: /(^0(\d-\d{4}|\d{2}-\d{3}|\d{3}-\d{2}|\d{4}-\d)-\d{4}$)|(^(050|070|080|090)-\d{4}-\d{4}$)|(^0120-\d{3}-\d{3}$)/, regexMsg: '電話番号(ハイフン付き)で指定してください' },
  { name: "section2", label: "J-クレジット申請用の追加情報", type: "section" },
  { name: "particiationStatus", label: "低炭素社会実行計画への参加状況", type: "select" },
  { name: "savingEntityId", label: "省エネ法特定事業者番号", type: "text" },
  { name: "globalWarmingEntityCode", label: "温対法特定排出者コード", type: "text" },
  { name: "enterDate", label: "入会申込日", type: "datestr" },
  { name: "enterDate2YearsAgo", label: "入会申込日(2年前)", type: "datestr" },
  { name: "buildingConfirmationDate", label: "建築確認済証交付日", type: "datestr" },
  { name: "completeDate", label: "完了検査済証交付日", type: "datestr" },
  { name: "completeEvidenceName", label: "完了検査等確認書類", type: "text" },
  { name: "targetStartDate", label: "認証対象開始日", type: "datestr" },
  { name: "targetEndDate", label: "認証対象終了日", type: "datestr" },
  { name: "applicationStartDate", label: "認証申請開始日", type: "datestr" },
  { name: "applicationEndDate", label: "認証申請終了日", type: "datestr" },
  { name: "buildingConfirmationEvidence", label: "建築確認済証交付エビデンス", type: "attachment" },
];

const Construct = ({ data, selectList, readOnly = true, submitActions = [], handlers }) => {
  return (
    <AppForm formFields={formFields} data={data} selectList={selectList} readonly={readOnly} submitActions={submitActions} handlers={handlers} columns={{ xs: 1, md: 3 }} />
  );
};

export default Construct;
